<template>
  <div class="divBox">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="标题"
        :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]"
        prop="topicTitle"
      >
        <v-input
          placeholder="请输入标题"
          :width="width"
          v-model="form.topicTitle"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="封面图"
        :rules="[
          {
            required: true,
            message: '请上传封面图',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="topicPicture"
      >
        <v-upload :limit="1" :imgUrls.sync="form.topicPicture" />
      </el-form-item>
      <el-form-item
        label="一级分类"
        :rules="[
          { required: true, message: '请选择一级分类', trigger: 'blur' },
        ]"
        prop="topicType1"
      >
        <v-select
          clearable
          :options="kindsFormList"
          v-model="form.topicType1"
          @change="changeTopicType1"
        />
      </el-form-item>
      <el-form-item
        label="二级分类"
        v-if="form.topicType1 == '7,8,9,10'"
        :rules="[
          { required: true, message: '请选择二级分类', trigger: 'blur' },
        ]"
        prop="topicType"
      >
        <v-select clearable :options="secKindsList" v-model="form.topicType" />
      </el-form-item>
      <el-form-item
        label="内容"
        :rules="[{ required: true, message: '请输入内容', trigger: 'blur' }]"
        prop="topicContent"
      >
        <div id="editor">
          <v-ueditor :maxLength="20000" v-model="form.topicContent" />
        </div>
      </el-form-item>
      <el-form-item
        label="关闭点赞"
        :rules="[
          { required: true, message: '请选择是否关闭点赞', trigger: 'change' },
        ]"
        prop="closePraise"
      >
        <v-select
          :width="width"
          :options="isTopOps"
          v-model="form.closePraise"
        />
      </el-form-item>
      <el-form-item
        label="关闭评论"
        :rules="[
          { required: true, message: '请选择是否关闭评论', trigger: 'change' },
        ]"
        prop="closeComment"
      >
        <v-select
          :width="width"
          :options="isTopOps"
          v-model="form.closeComment"
        />
      </el-form-item>
      <el-form-item
        label="是否置顶"
        :rules="[
          { required: true, message: '请选择是否置顶', trigger: 'blur' },
        ]"
        prop="isTop"
      >
        <v-select :width="width" :options="isTopOps" v-model="form.isTop" />
      </el-form-item>

      <el-form-item
        v-if="form.isTop === 1"
        label="置顶排序"
        :rules="[
          {
            required: true,
            message: '请输入置顶排序值',
            trigger: ['blur', 'change'],
          },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ]"
        prop="isTopSort"
      >
        <v-input
          placeholder="请输入置顶排序值"
          v-model="form.isTopSort"
          min="1"
          :width="width"
          type="number"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="发布状态"
        :rules="[{ required: true, message: '请选择状态', trigger: 'blur' }]"
        prop="status"
      >
        <v-select :width="width" :options="statusOps" v-model="form.status" />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import {
  addTopicURL,
  updateTopicURL,
  getTalentUserListURL,
  getTopicInfoByIdURL,
} from "./api.js";
import {
  statusMap,
  setStatusOps,
  isTopMap,
  setIsTopOps,
  secKindsList,
  secKindsListMap,
  kindsFormList,
} from "./map.js";

export default {
  name: "partnerForm",
  data() {
    return {
      kindsFormList,
      secKindsList,
      width: 220,
      communityList: [],
      // talentTypeOps: [],
      // talentTypeMap: {},
      // talentUserOps: [],
      // talentUserMap: {},
      // isTopSortOps: [],
      isTopOps: setIsTopOps(),
      isTopMap,
      statusOps: setStatusOps(),
      statusMap,
      submitConfig: {
        queryUrl: getTopicInfoByIdURL,
        submitUrl: "",
      },
      form: {
        id: null,
        topicType1: "",
        topicType: "",
        optUser: undefined, // 用户id
        communityId: undefined, // 园区id
        talentUserId: "",
        isTop: 1, //是否置顶
        isTopSort: undefined, // 是否置顶排序
        closeComment: 0, //关闭点赞
        closePraise: 0, //关闭评论
        topicTitle: "", // 话题标题
        topicContent: "", // 富文本内容
        nowStatus: "",
        remark: "", // 备注
        status: 1,
        subPicture: "",
        topicPicture: "",
        // adminId: null,
      },
      communityId: null,
      onOff: true, // 是否进入编辑
      id: "",
      changeFlag: false,
    };
  },
  created() {
    const { id } = this.$route.query;
    this.id = id;
    // this.form.communityId = communityId;
    if (id !== undefined) {
      this.$nextTick(() => {
        this.$refs.formPanel.getData({ id });
      });
    }
    this.submitConfig.submitUrl =
      id !== undefined ? updateTopicURL : addTopicURL;
    this.onOff = id !== undefined;
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    changeTopicType1(value) {
      this.form.topicType = value;
      // if (this.id) {
      //   if (this.changeFlag) {
      //     this.form.topicType = "";
      //     if (
      //       value == "11" ||
      //       value == "12" ||
      //       value == "13" ||
      //       value == "14"
      //     ) {
      //       this.form.topicType = value;
      //     }
      //   } else {
      //     this.changeFlag = true;
      //   }
      // } else {
      //   this.form.topicType = "";
      //   if (value == "11" || value == "12" || value == "13" || value == "14") {
      //     this.form.topicType = value;
      //   }
      // }
    },
    changetalentType(value) {
      console.log(value, 89);
      this.form.talentUserId = "";
      this.getTalentUserList(value);
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key] || this.form[key];
      });
      if (
        this.form.topicType &&
        [7, 8, 9, 10].indexOf(this.form.topicType) != -1
      ) {
        this.form.topicType1 = "7,8,9,10";
      } else {
        this.form.topicType1 = String(data["topicType"]);
      }
      this.form.topicType = String(data["topicType"]);
    },
    submitBefore() {
      if (!this.form.optUser) {
        this.form.optUser = this.$store.state.app.userInfo.userId;
      }

      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
